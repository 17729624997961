<template>

<router-link :to="{name: route, params: params || defaultParams}" class="item" :class="{'is-active': active}">

	<app-icon :icon="icon" class="item-icon" v-if="icon" />
	
	<div class="item-text">{{ text }}</div>

	<div class="item-count" v-if="count || forceCount" :class="{'is-highlight': countHighlight}">{{ count }}</div>
	<div class="item-count is-highlight is-alert" v-if="alert">!</div>

</router-link>

</template>

<script>

export default {

	props: ['text', 'route', 'icon', 'params', 'alert', 'ignoreActive', 'count', 'forceCount', 'count-highlight'],

	computed: {

		defaultParams: function() {

			return {
				organisation: this.$store.getters['context/organisation'].slug,
				convention: this.$store.getters['context/convention'].slug
			}

		},

		active: function() {

			return !this.ignoreActive && this.route !== undefined && (this.route === this.$route.name || this.route === this.$route.meta.default || this.$_.contains(this.$route.meta.parents, this.route))

		}

	}

}

</script>

<style scoped>

.item {
	display: flex;
	flex-direction: row;
	min-height: 40px;
	align-items: center;
	color: #bfc4cb;
	padding: 0px 20px;
	cursor: pointer;
	margin-bottom: 4px;
}

.item:first-child {
	margin-top: 20px;
}

.item:hover {
	background-color: #626e7f;
	color: #fff;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;
	padding: 0px 10px;
}

.item.is-active {
	background-color: #ffbe7a;
    color: #4d5b6e;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;
	padding: 0px 10px;
}

.item-icon {
	width: 32px;
	text-align: center;
	flex-shrink: 0;
	font-size: 20px;
	margin-right: 10px;
}

.is-mobile .item-icon {
	font-size: 20px;
}

.item-text {
	font-size: 16px;
	font-weight: 300;
	flex-grow: 1;
	letter-spacing: 1px;
}

.item-count {
	min-width: 24px;
	height: 24px;
	flex-shrink: 0;
	border-radius: 4px;
	text-align: center;
	background-color: #687589;
	font-weight: 400;
	padding: 0px 4px;
	line-height: 24px;
	color: #b4b8bc;
}

.item-count.is-highlight {
	background-color: #dd4848;
	color: #fff;
}

.item.is-active .item-count {
	background-color: #fff;
	color: #333;
}

.item-count.is-alert {
	font-weight: 700;
}

</style>
